@charset "UTF-8";
/* height */
/* RESPONSIVE */
/* TYPOGRAPHY */
/* ELEMENTS */
/* PATTERNS */
/* ACCESSIBILITY */
@supports (animation: none) {
  .Video {
    opacity: 0;
    animation: fade-in 1000ms forwards;
    animation-delay: 1500ms; } }

.Video--resizing {
  overflow: hidden; }

.Video-Inner {
  position: relative;
  margin-top: 0;
  margin-bottom: 0; }
  @media (max-width: 767px) {
    .Video-Inner {
      margin: 0 -12px; } }
  @media print {
    .Video-Inner {
      max-width: 45rem; } }

.Video-Frame {
  position: relative; }

.Video-Mask {
  position: relative;
  background-color: #312d26;
  height: 0;
  padding-top: 56.25%;
  overflow: hidden;
  background-color: #312d26;
  background-image: linear-gradient(45deg, #2b2722 25%, transparent 25%, transparent 75%, #2b2722 75%, #2b2722), linear-gradient(-45deg, #2b2722 25%, transparent 25%, transparent 75%, #2b2722 75%, #2b2722);
  background-size: 120px 120px;
  box-shadow: 0 5px 12px rgba(0, 0, 0, 0.5); }
  @media print {
    .Video-Mask {
      border: 1px solid #999;
      background: transparent;
      position: relative; }
      .Video-Mask:before {
        content: url("data:image/svg+xml;utf8,<svg width='712' height='428' xmlns='http://www.w3.org/2000/svg'><g><path d='M.835.5l711.03 425.82M707.165 2.5L.835 427.5' stroke='#ccc'/><text font-family='ArialMT, Arial' font-size='18' fill='#ddd'><tspan x='275.438' y='300'>bilden är inte laddad</tspan></text></g></svg>");
        position: absolute;
        left: 0;
        top: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%; } }
  .Video-Mask--43 {
    padding-top: 75%; }
  .Video-Mask--235 {
    padding-top: 42.5%; }

.Video-Poster {
  -webkit-appearance: none;
  display: inline-block;
  vertical-align: middle;
  margin: 0;
  padding: 0;
  color: inherit;
  background-color: transparent;
  border-width: 0;
  border-radius: 0;
  font-family: "proxima-nova", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-weight: normal;
  font-size: 1em;
  line-height: inherit;
  cursor: pointer;
  outline: none;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  position: absolute;
  top: -2px;
  left: -2px;
  z-index: 1;
  width: 101%;
  width: calc(100% + 4px);
  height: 101%;
  height: calc(100% + 4px);
  opacity: 0.7;
  transition: opacity 300ms; }
  @media print {
    .Video-Poster {
      opacity: 1; } }
  .Video-Poster:after {
    content: '';
    pointer-events: none;
    opacity: 0; }
  .Video-Poster:hover {
    opacity: 1; }
    @media (pointer: coarse) {
      .Video-Poster:hover {
        opacity: 0.7; } }
  .Video-Poster:focus:after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    opacity: 1;
    background-color: rgba(194, 156, 14, 0.3);
    transition: opacity 300ms; }
  .Video-Poster:focus:not(.focus-visible):after {
    display: none; }
  .Video-Poster--active {
    z-index: 0;
    opacity: 0; }
  .Video-Poster-Img {
    display: block;
    width: 100%;
    height: auto;
    min-height: 10px; }
    @supports (object-fit: cover) {
      .Video-Poster-Img {
        height: 100%;
        object-fit: cover;
        object-position: 50% 50%; } }
    .no-js .Video-Poster-Img {
      display: none; }
    .Video-Poster-Img-Fallback {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%; }
      .no-js .Video-Poster-Img-Fallback {
        display: block; }

.Video-Iframe {
  position: absolute;
  top: -2px;
  left: -2px;
  width: 101%;
  width: calc(100% + 4px);
  height: 101%;
  height: calc(100% + 4px);
  background-color: transparent;
  transition: opacity 300ms; }
  .Video-Poster + .Video-Iframe {
    z-index: 0;
    opacity: 0; }
  .Video-Iframe--active {
    z-index: 1;
    opacity: 1; }

.Video-Title {
  margin: 0 0 0.5em;
  text-align: center; }
  .Video-Title-Logo-Text {
    display: block;
    height: 0;
    overflow: hidden;
    text-indent: -9999px; }
  .Video-Title-Logo svg, .Video-Title-Logo img {
    max-width: 80vw;
    height: auto; }
    @media (max-width: 767px) {
      .Video-Title-Logo svg, .Video-Title-Logo img {
        max-width: 220px; } }

.Video-Caption {
  font-family: "mrs-eaves-roman-lining", baskerville, georgia, serif;
  font-weight: normal;
  transform: scale(0.978);
  transform-origin: 50% 50%;
  margin: 0;
  padding: 24px 0 0;
  font-size: 1.25rem;
  line-height: 1.3;
  text-align: center; }
  .wf-mrseavesromanlining-n4-active .Video-Caption {
    transform: none; }
  @media (max-width: 767px) {
    .Video-Caption {
      padding-top: 36px;
      padding-left: 12px;
      padding-right: 12px; } }
  .Video-Caption a {
    font-family: "mrs-eaves-roman-small-caps", "Bodoni 72 smallcaps", "Calibri", "Gill Sans MT", georgia, serif;
    font-weight: normal;
    font-style: normal;
    font-weight: normal;
    letter-spacing: 0.07em; }
    .wf-mrseavesromansmallcaps-n4-active .Video-Caption a {
      letter-spacing: normal; }

.Video-Play {
  -webkit-appearance: none;
  display: inline-block;
  vertical-align: middle;
  margin: 0;
  padding: 0;
  color: inherit;
  background-color: transparent;
  border-width: 0;
  border-radius: 0;
  font-family: "proxima-nova", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-weight: normal;
  font-size: 1em;
  line-height: inherit;
  cursor: pointer;
  outline: none;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  position: absolute;
  right: 50%;
  top: 50%;
  z-index: 1;
  width: 92px;
  height: 92px;
  margin: -46px -46px 0 0;
  border-radius: 100%;
  transition: all 0ms;
  cursor: pointer;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  outline: none;
  user-select: none; }
  .Video-Play:focus {
    outline: none; }
  .Video-Play.focus-visible:focus {
    outline: none;
    box-shadow: 0 0 0 15px rgba(194, 156, 14, 0.3);
    transition: box-shadow 500ms; }
    @media (pointer: coarse) {
      .Video-Play.focus-visible:focus {
        box-shadow: initial; } }
  .Video-Play--play-stop {
    transition: transform 300ms, opacity 300ms; }
  .Video--resizing .Video-Play {
    opacity: 0; }
  @media (min-width: 600px) {
    .Video-Play {
      width: 112px;
      height: 112px;
      margin: -56px -56px 0 0; } }
  .Video-Play .icon-play-triangle {
    fill: #fff; }
  .Video-Play .icon-play-circle {
    fill: none; }
    @media print {
      .Video-Play .icon-play-circle {
        fill: #fff; } }
  .Video-Play .icon-play-disc {
    fill: none; }
  .Video-Play .icon-play-circle {
    fill: #fff; }
  .Video-Play--active {
    display: none; }
    @supports (transition: all 0ms) {
      .Video-Play--active {
        display: block; } }
    .Video-Play--active .icon-play-disc {
      fill: #c29c0e; }
    .Video-Play--active .icon-play-triangle {
      fill: rgba(255, 255, 255, 0); }
    .Video-Play--active .icon-play-circle {
      fill: transparent; }
    .Video-Play--active .icon-play-square {
      fill: #000; }
  .Video-Play svg {
    width: 100%;
    height: 100%; }

.Video-Ally-Open {
  display: block; }
  .Video-Poster--active .Video-Ally-Open {
    display: none; }

.Video-Ally-Close {
  display: none; }
  .Video-Poster--active .Video-Ally-Close {
    display: block; }
