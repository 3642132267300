// Breakpoints
//
// Status: near final.
//
// Styleguide 1.13.

// Variables - Width Breakpoints
//
// Responsive design breakpoints are designed around common scenarios. Content should be designed to reformat when the content demands it, not ideals of what a device is, but the truth is that common breakpoints make development easier and more reliable by giving clear, and predictable, starting points to work from. If the content demands more finess, it should dictate it's own breakpoints.
//
// $xs-and-up - min-width: XS and greater: larger than all known phones in portrait, most small (7") tablets
// $sm-and-up - min-width: SM and greater: larger (9") tablets in portrait
// $md-and-up - min-width: MD and greater: smaller desktops and larger (9") tablets in landscape
// $lg-and-up - min-width: LG and greater: larger desktops, very large tablets
// $less-than-xs - max-width: up to XXS
// $less-than-sm - max-width: up to XS
// $less-than-md - max-width: up to MD
// $less-than-lg - max-width: up to LG
//  
// markup:
// <div style="--width-var: calc({$modifiers}); width: {$modifiers}; width: var(--width-var); margin: 15px auto; padding: 5px 0; border: 1px solid #79ad3e; color: #79ad3e; background: #ded; text-align: center;">{$modifiers}</div>
// <!-- the CSS variables stuff is just to demonstrate this SASS in this pattern guide. It's not recommended -->
//
// Styleguide 1.13.1.

$xs-and-up: 600px;
$sm-and-up: 768px;
$md-and-up: 980px;
$lg-and-up: 1200px;

$less-than-xs: $xs-and-up - 1px;
$less-than-sm: $sm-and-up - 1px;
$less-than-md: $md-and-up - 1px;
$less-than-lg: $lg-and-up - 1px;

// Variables - Height Breakpoints
//
// Height breakpoints aid a better user experience on shallow screens, such as smaller laptops or windows that have a lot of browser UI. If your content demands more precise breakpoints, create your own, but the ones below are used to start the process about thinking of what content should look like on different height screens. Mobile devices often override these breakpoints with width breakpoints following them, but the decision of what to do for each combination of screen size and orientation should be left up to the design and dev team.
//
// $h-sm-and-up - min-height: 400px and greater
// $h-md-and-up - min-height: 700px and greater
// $h-lg-and-up - min-height: 1200px and greater
// $h-less-than-sm - max-height: 399px
// $h-less-than-md - max-height: 699px
// $h-less-than-lg - max-height: 1200px
//  
// markup:
// <div style="--height-var: calc({$modifiers}); width: 300px; min-width: 20%; margin: 15px auto; height: {$modifiers}; height: var(--height-var);  padding: 0 15px; border: 1px solid #79ad3e; background: #ded; color: #79ad3e; display: flex; align-items: center; justify-content: center;">{$modifiers}</div>
// <!-- the CSS variables stuff is just to demonstrate this SASS in this pattern guide. It's not recommended -->
//
// Styleguide 1.13.2

/* height */ 

$h-sm-and-up: 500px; // why 460? biger than an iPhone 6+ on it's side is 414 high, a Nexus 6P is 412px. More like a tablet on it's side. (I know, don't fit to the device, blah blah)
$h-md-and-up: 721px; // why 721? because Samsung S8s are 720px high, and when combined with Samsung Browser - which can see 100vh as 622px or 720px high depending on if you're scrolled or not - we don't want elements to move around if the only thing you're doing is scrolling the page.
$h-lg-and-up: 1200px;

$h-less-than-sm: $h-sm-and-up - 1px;
$h-less-than-md: $h-md-and-up - 1px;
$h-less-than-lg: $h-lg-and-up - 1px;