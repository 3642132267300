$font-fallback: -apple-system, 
                BlinkMacSystemFont, 
                'Segoe UI', 
                Roboto, 
                Oxygen-Sans, 
                Ubuntu, 
                Cantarell, 
                'Helvetica Neue', 
                sans-serif;
$font-default: 'proxima-nova',$font-fallback;
$font-serif: 'mrs-eaves-roman-lining', baskerville, georgia, serif;
$font-smallcaps: 'mrs-eaves-roman-small-caps', "Bodoni 72 smallcaps", "Calibri", "Gill Sans MT", georgia, serif;
$font-color: #000;
$font-size-base: 18px;
$font-size-xxxs: 0.6rem;
$font-size-xxs: 0.7rem;
$font-size-xs: 0.8rem;
$font-size-sm: 0.9rem;
$font-size-md: 1rem;
$font-size-md2: 1.25rem;
$font-size-lg: 1.5rem;
$font-size-lg-sm: 1.25rem;  // scale down for mobile
$font-size-xl: 2rem;
$font-size-xl-sm: 1.5rem;  // scale down for mobile
$font-size-xxl: 2.8rem;
$font-size-xxl-sm: 2rem;  // scale down for mobile
$font-size-print: 16pt;
$line-height-default: 1.64;
$line-height-header: 1.3;
